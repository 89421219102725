import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Text, Flex, Image as ImageBase } from 'rebass'
import { fonts, colors } from 'theme'
import { Link } from 'components'
import { linkResolver } from 'utils'

const Container = system(
  {
    extend: Flex,
    justifyContent: 'center',
    pt: '90px',
    pb: '45px',
  },
  'space',
)

const FullContainer = system(
  {
    extend: Flex,
    flex: 1,
    flexDirection: ['column', 'row'],
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: ['100%', '740px', '1100px'],
    px: '20px',
    width: '100%',
  },
  'width',
  'flex',
  'maxWidth',
)

const Item = system(
  {
    extend: Box,
    flex: [1, '0 0 50%', '0 0 33%'],
    mb: '40px',
    px: '15px',
    maxWidth: ['100%', '50%', '33%'],
    width: '100%',
  },
  'space',
  'width',
  'maxWidth',
)

const Image = system(
  {
    extend: ImageBase,
    height: '274px',
    width: '100%',
  },
  'height',
  'width',
  {
    objectFit: 'cover',
  },
)

const DescriptionContainer = system(
  {
    extend: Flex,
    p: '25px 0px',
  },
  'space',
)

const ItemNumber = system(
  {
    extend: Text,
    fontSize: '72px',
    lineHeight: '1',
    fontWeight: '900',
    color: '#eaeaea',
    fontFamily: fonts.main,
  },
  {
    letterSpacing: '-.01em',
  },
)

const TitleContainer = system(
  {
    extend: Box,
    flex: 1,
    ml: '20px',
    mb: '20px',
  },
  'flex',
  'space',
)

const Title = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '24px',
    lineHeight: '1.25',
  },
  {
    letterSpacing: '0.025em',
    a: {
      color: '#151515',
    },
    'a:hover': {
      textDecoration: 'none',
      color: colors.lightTeal,
    },
  },
)

const Description = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    color: '#777',
    fontSize: '15px',
    mt: '15px',
  },
  'space',
  {
    letterSpacing: '0.075em',
  },
)

export const MenuItems = ({ data }) => {
  const pad = (n, width, z) => {
    z = z || '0'
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
  }

  return (
    <Container>
      <FullContainer>
        {data.items &&
          data.items.map((item, idx) => (
            <Item key={idx}>
              <Link to={linkResolver(item.link)}>
                <Image src={item.image.url} />
              </Link>
              <DescriptionContainer>
                <ItemNumber>{pad(idx + 1, 2)}</ItemNumber>
                <TitleContainer>
                  <Title>
                    <Link to={linkResolver(item.link)}>{item.name}</Link>
                  </Title>
                  {item.description && (
                    <Description>
                      {typeof item.description === 'object'
                        ? PrismicDOM.RichText.asText(item.description)
                        : item.description}
                    </Description>
                  )}
                </TitleContainer>
              </DescriptionContainer>
            </Item>
          ))}
      </FullContainer>
    </Container>
  )
}
